import { ArrowLeftOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { App, Button, Col, Form, Input, Row, Select, Space, message } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import companyApi from '../../../apis/CompanyApi';
import trapApi from '../../../apis/TrapApi';
import ConstantLabel from '../../../components/ConstantLabel/ConstantLabel';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import { Company, Trap } from '../../../models/Entities';
import { plagues } from '../../../models/Types';
import alertService from '../../../services/AlertService';
import stringService from '../../../services/StringService';

/**
 * Returns the admin trap page.
 * @returns the admin trap page.
 */
const AdminTrapPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const { modal } = App.useApp();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const params = useParams<ParamsType>();

    const [loading, setLoading] = useState<'initializing' | 'loading' | 'deleting'>();
    const [companies, setCompanies] = useState<Company[]>([]);
    const [trap, setTrap] = useState<Trap>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('initializing');

                // load trap
                let trap: Trap;
                if (params.id === 'new') {
                    trap = {};
                } else {
                    trap = await trapApi.get(+params.id!);
                }
                form.setFieldsValue(trap);
                setTrap(trap);

                // load companies
                const companies = await companyApi.list(0, 1000, 'name', true);
                setCompanies(companies.content);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [form, intl, params.id]);

    /*** METHODS ***/

    const save = useCallback(
        async (values: any) => {
            try {
                setLoading('loading');

                let updatedTrap: Trap = Object.assign({}, trap, values);
                if (updatedTrap.id) {
                    updatedTrap = await trapApi.update(updatedTrap);
                } else {
                    updatedTrap = await trapApi.create(updatedTrap);
                }

                message.success(intl.formatMessage({ id: 'status.saved' }));
                setTrap(updatedTrap);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        },
        [intl, trap]
    );

    const remove = async () => {
        modal.confirm({
            title: intl.formatMessage({ id: 'trap.deleteModal.title' }),
            okButtonProps: { loading: loading === 'deleting', danger: true },
            onOk: async () => {
                try {
                    setLoading('deleting');

                    await trapApi.delete(trap?.id!);
                    message.success(intl.formatMessage({ id: 'status.deleted' }));

                    navigate('/admin/traps');
                } catch (error) {
                    alertService.displayError(error, intl);
                } finally {
                    setLoading(undefined);
                }
            }
        });
    };

    /*** VISUAL ***/

    const companyOptions = companies
        .sort((a, b) => stringService.sort(a.name, b.name))
        .map((company) => (
            <Select.Option key={company.id} value={company.id}>
                {company.name}
            </Select.Option>
        ));

    const plagueOptions = plagues.map((p) => (
        <Select.Option key={p} value={p}>
            <ConstantLabel prefix="plague.type." value={p} />
        </Select.Option>
    ));

    return (
        <LayoutComponent
            title={<FormattedMessage id="traps.title" />}
            menu="traps"
            path={[
                { path: '/admin/traps', name: <FormattedMessage id="traps.title" /> },
                { path: `/admin/traps/${params.id}`, name: trap?.uuid }
            ]}
        >
            <Form form={form} onFinish={save} colon={false} layout="vertical">
                <Row gutter={[12, 12]}>
                    <Col span={24} lg={6}>
                        <Form.Item
                            label={<FormattedMessage id="trap.uuid" />}
                            name="uuid"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" maxLength={50} />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={6}>
                        <Form.Item
                            label={<FormattedMessage id="trapPlot.plague" />}
                            name="plague"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select size="large">{plagueOptions}</Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[12, 12]}>
                    <Col span={24} lg={12}>
                        <Form.Item label={<FormattedMessage id="trap.company" />} name={['company', 'id']}>
                            <Select size="large" showSearch filterOption={stringService.filterOptions} allowClear>
                                {companyOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 24]} className="buttons">
                    <Col span={24}>
                        <Space>
                            <Link to="/admin/traps">
                                <Button size="large" icon={<ArrowLeftOutlined />}></Button>
                            </Link>
                            <Button type="primary" htmlType="submit" size="large" loading={loading === 'loading'} icon={<SaveOutlined />}>
                                <FormattedMessage id="button.save" tagName="span" />
                            </Button>
                            {trap && trap.id && <Button danger size="large" onClick={remove} icon={<DeleteOutlined />}></Button>}
                        </Space>
                    </Col>
                </Row>
            </Form>
        </LayoutComponent>
    );
};
export default AdminTrapPage;
type ParamsType = { id: string };
