import { Button, Col, Image, Row, Space } from 'antd';
import { Footer, Header } from 'antd/es/layout/layout';
import { Link } from 'react-router-dom';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import { ReactComponent as LogoIcon } from '../../resources/images/agrisens-icon.svg';
import trap from '../../resources/images/trap.png';
import styles from './HomePage.module.scss';
import pozosurLogo from '../../resources/images/pozosur-logo.png';
import { DownCircleOutlined, MailOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import app from '../../resources/images/agrisens-app.svg';

/**
 * Returns the home page.
 * @returns the home page.
 */
const HomePage: React.FC = () => {
    /*** HOOKS ***/

    const [desktop] = useResponsiveLayout();

    /*** METHODS ***/

    /*** VISUAL ***/

    return (
        <>
            <div className={`${styles.container} ${!desktop && styles.mobile}`}>
                <div className={styles.top}>
                    <Header className={styles.header}>
                        <Link to="/" className={styles.title}>
                            <LogoIcon className={styles.logo} />{' '}
                            <span>
                                <FormattedMessage id="agriSens" />
                            </span>
                        </Link>
                        <Space size="middle">
                            <Link to="/signin" className={styles.options}>
                                <span>
                                    <FormattedMessage id="signIn.login.link.home" />
                                </span>
                            </Link>
                            <Link to="/contact" className={styles.options}>
                                <span>
                                    <FormattedMessage id="home.main.contact.link" />
                                </span>
                            </Link>
                        </Space>
                    </Header>
                    <div className={styles.text}>
                        <h1>
                            <FormattedMessage id="home.main.header" />
                        </h1>
                        <p>
                            <FormattedMessage id="home.main.description1" />
                        </p>
                        <Link to="/signin">
                            <Button type="primary" className={styles.buttons}>
                                <FormattedMessage id="button.start" tagName="span" />
                            </Button>
                        </Link>
                    </div>
                </div>
                <Row className={styles.benefits}>
                    <Col span={24} className={styles.heading}>
                        <h2>
                            <FormattedMessage id="home.main.benefits.header" />
                        </h2>
                    </Col>
                </Row>
                <Row className={styles.benefits}>
                    <Col className={styles.imageContainer} xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Image src={trap} alt="AgriSens trap" className={styles.image} preview={false} />
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <div className={styles.text}>
                            <h2>
                                {desktop && <span className={styles.dot}>01</span>}
                                <FormattedMessage id="home.main.benefits.heading.1" />
                            </h2>
                            <p>
                                <FormattedMessage id="home.main.benefits.description.1" />
                            </p>
                        </div>

                        <Row>
                            <div className={styles.text}>
                                <h2>
                                    {desktop && <span className={styles.dot}>02</span>}
                                    <FormattedMessage id="home.main.benefits.heading.2" />
                                </h2>
                                <p>
                                    <FormattedMessage id="home.main.benefits.description.2" />
                                </p>
                            </div>
                        </Row>
                        <Row>
                            <div className={styles.text}>
                                <h2>
                                    {desktop && <span className={styles.dot}>03</span>}
                                    <FormattedMessage id="home.main.benefits.heading.3" />
                                </h2>
                                <p>
                                    <FormattedMessage id="home.main.benefits.description.3" />
                                </p>
                            </div>
                        </Row>
                        <Row>
                            <div className={styles.text}>
                                <h2>
                                    {desktop && <span className={styles.dot}>04</span>}
                                    <FormattedMessage id="home.main.benefits.heading.4" />
                                </h2>
                                <p>
                                    <FormattedMessage id="home.main.benefits.description.4" />
                                </p>
                            </div>
                        </Row>
                    </Col>
                </Row>
                <Row className={styles.description}>
                    <p>
                        <FormattedMessage id="home.main.description2" />
                    </p>
                </Row>
                <Row className={styles.functionalities}>
                    <Col span={24} className={styles.heading}>
                        <h2>
                            <FormattedMessage id="home.main.functionalities.header" />
                        </h2>
                    </Col>
                </Row>
                <Row className={styles.functionalities}>
                    <Col className={styles.imageContainer} xs={24} sm={24} md={24} lg={12} xl={12}>
                        <div className={styles.text}>
                            <h3>
                                {desktop && <DownCircleOutlined className={styles.icon} />}
                                <FormattedMessage id="home.main.functionalities.heading.1" />
                            </h3>
                            <h3>
                                {desktop && <DownCircleOutlined className={styles.icon} />}
                                <FormattedMessage id="home.main.functionalities.heading.2" />
                            </h3>
                            <h3>
                                {desktop && <DownCircleOutlined className={styles.icon} />}
                                <FormattedMessage id="home.main.functionalities.heading.3" />
                            </h3>
                            <h3>
                                {desktop && <DownCircleOutlined className={styles.icon} />}
                                <FormattedMessage id="home.main.functionalities.heading.4" />
                            </h3>
                            <h3>
                                {desktop && <DownCircleOutlined className={styles.icon} />}
                                <FormattedMessage id="home.main.functionalities.heading.5" />
                            </h3>
                        </div>
                    </Col>
                    <Col className={styles.imageContainer} xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Image src={app} alt="AgriSens app" className={styles.image} preview={false} />
                    </Col>
                </Row>
                <Row className={styles.clients}>
                    <Col span={24}>
                        <h2>
                            <FormattedMessage id="clients.header" />
                        </h2>
                        <p>
                            <FormattedMessage id="clients.description.1" />
                        </p>
                        <Image src={pozosurLogo} alt="Pozosur" className={styles.image} preview={false} />
                    </Col>
                </Row>
                <Row className={styles.contact}>
                    <Col span={24}>
                        <h2>
                            <FormattedMessage id="home.main.contact.header" />
                        </h2>
                        <p>
                            <FormattedMessage id="home.main.contact.description" />
                        </p>

                        <Link to="/contact">
                            <Button type="primary" className={styles.buttons} icon={<MailOutlined className={styles.icon} />}>
                                {desktop && <FormattedMessage id="button.contact" tagName="span" />}
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <Row className={styles.ads} justify="center" align="middle">
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Space size="large" align="center">
                            <Image src="/images/logo-murcia.png" alt="Ayuntamiento de Murcia" preview={false} />
                            <Image src="/images/logo-murcia-empleo.png" alt="Ayuntamiento de Murcia - Plan de empleo" preview={false} />
                        </Space>
                    </Col>
                </Row>

                <Footer className={desktop ? styles.footer : styles.mobileFooter}>
                    <div>
                        <Space className={styles.links} size="large">
                            <Link to="/legal-notice" className={styles.link}>
                                <FormattedMessage id="common.legalNotice" />
                            </Link>
                            <Link to="/privacy-policy" className={styles.link}>
                                <FormattedMessage id="common.privacyPolicy" />
                            </Link>
                        </Space>
                    </div>
                    <div className={styles.copyright}>
                        <a href="https://agrisens.es" target="_blank" rel="noreferrer">
                            ©2025 AgriSens Technologies S.L.
                        </a>
                    </div>
                </Footer>
            </div>
        </>
    );
};
export default HomePage;
